import { store } from '../utils/create-store'

export default function formatCurrency(currency, amount, showCurrency = true) {
  const minorUnitCountLookup = store.getState().default.mappings
    .currencyMinorUnitCountLookup
  const minorUnitCount = minorUnitCountLookup[currency]
  let workingString = amount.toString()
  let minusSignNeeded = false

  if (minorUnitCount === undefined) {
    throw new Error('Unknown currency: ' + currency)
  }

  if (workingString[0] === '-') {
    minusSignNeeded = true
    workingString = workingString.substring(1, workingString.length)
  }

  workingString = workingString
    .split('')
    .reverse()
    .join('')
  let minorUnitString = workingString.substring(0, minorUnitCount)
  workingString = workingString.substring(minorUnitCount)
  workingString = (workingString.match(/.{1,3}/g) || ['0']).join(',')
  workingString = workingString
    .split('')
    .reverse()
    .join('')
  minorUnitString = minorUnitString
    .split('')
    .reverse()
    .join('')

  if (minorUnitCount !== 0) {
    workingString =
      workingString + '.' + minorUnitString.padStart(minorUnitCount, '0')
  }

  if (showCurrency) {
    workingString = currency + ' ' + workingString
  }

  if (minusSignNeeded) {
    workingString = '(' + workingString + ')'
  }

  return workingString
}
