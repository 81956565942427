import { createStore, applyMiddleware, combineReducers } from 'redux'
import loggingMiddleware from '../middleware/logging-middleware'
import * as reducers from '../reducers/settings-reducers'

function createStoreWithMiddleware(data) {
  const reducer = combineReducers(reducers)
  const finalCreateStore = applyMiddleware(loggingMiddleware)(createStore)
  const store = finalCreateStore(
    reducer,
    data,
    /* istanbul ignore next */
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )

  return store
}

const store = createStoreWithMiddleware()

export { store }
