import * as actionCreators from '../actions/action-creator'
import { store } from '../utils/create-store'

export default function(resource, params = {}) {
  let action, path, service
  switch (resource) {
    case 'accounts':
      path = '/accounts'
      action = actionCreators.setAccounts
      service = store.getState().default.services.finance
      break
    case 'cards':
      path = '/cards'
      action = actionCreators.setCards
      service = store.getState().default.services.finance
      break
    case 'entities':
      path = '/entities'
      action = actionCreators.setEntities
      service = store.getState().default.services.finance
      break
    case 'initialState':
      path = '/initial-state'
      action = actionCreators.setInitialState
      service = store.getState().default.services.finance
      break
    case 'group':
      path = '/group'
      action = actionCreators.setGroup
      service = store.getState().default.services.finance
      break
    case 'overview':
      path = '/overview'
      action = actionCreators.setOverview
      service = store.getState().default.services.administration
      break
    case 'results':
      path = '/results'
      action = actionCreators.setResults
      service = store.getState().default.services.administration
      break
    case 'session':
      path = '/session'
      action = () => {
        store.dispatch(actionCreators.setSessionStateToSignedIn())
      }
      service = store.getState().default.services.finance
      break
    case 'transactions':
      if (params.next_offset) {
        path = `/transactions?offset=${params.next_offset}`
        action = actionCreators.addTransactions
      } else {
        path = '/transactions'
        action = actionCreators.setTransactions
      }
      service = store.getState().default.services.finance
      break

    /* istanbul ignore next */
    default:
      path = '/transactions'
      action = actionCreators.setTransactions
      service = store.getState().default.services.finance
  }

  fetch(service + path, {
    method: 'GET',
    credentials: 'include',
  })
    .then(response => {
      switch (response.status) {
        case 200:
          return response.json()
        case 401:
          store.dispatch(actionCreators.setSessionStateToSignedOut())
          throw new Error()
        default:
          throw new Error()
      }
    })
    .then(parsedResponse => store.dispatch(action(parsedResponse)))
    .catch(function(error) {
      /* istanbul ignore next */
      console.log(
        'There has been a problem fetching resource.',
        resource,
        error
      )
    })
}
