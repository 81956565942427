import defaultState from '../utils/default-state'

export default function appSettingReducers(state = defaultState(), action) {
  switch (action.type) {
    case 'ADD_TRANSACTIONS':
      return {
        ...state,
        transactions: state.transactions.concat(action.value.data),
        transactionsMetadata: action.value.metadata,
      }
    case 'SET_ACCOUNTS':
      return { ...state, accounts: action.value }
    case 'SET_CARDS':
      return { ...state, cards: action.value }
    case 'SET_ENTITIES':
      return { ...state, entities: action.value }
    case 'SET_INITIAL_STATE':
      return { ...state, ...action.value }
    case 'SET_GROUP':
      return { ...state, group: action.value }
    case 'SET_OVERVIEW':
      return { ...state, overview: action.value }
    case 'SET_RESULTS':
      return { ...state, results: action.value }
    case 'SET_SESSION_STATE':
      return { ...state, session: action.value }
    case 'SET_TRANSACTIONS':
      return {
        ...state,
        transactions: action.value.data,
        transactionsMetadata: action.value.metadata,
      }
    default:
      return state
  }
}
