export default function loggingMiddleware({ dispatch, getState }) {
  return function(next) {
    return function(action) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV === 'development') {
        console.log('loggingMiddleware action received:', action)
      }
      return next(action)
    }
  }
}
