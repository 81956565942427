import moment from 'moment'
import React, { Component } from 'react'
import { Accordion, Alert, Badge, Button, Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'

class Results extends Component {
  state = {
    showPassingResults: false,
  }

  componentDidMount() {
    fetchResources('results')
  }

  render() {
    const passingResults = this.props.results.filter(
      result => result.latest_success
    )
    const failingResults = this.props.results.filter(
      result => !result.latest_success
    )

    return (
      <>
        <Table responsive>
          <tbody>
            {failingResults.map(failingResult => (
              <ResultRow key={failingResult.name} result={failingResult} />
            ))}
          </tbody>
        </Table>
        <Accordion in={this.state.showPassingResults}>
          <Accordion.Toggle
            eventKey='0'
            id='application.results.passingResultsToggle'
          >
            Show passing results
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Table responsive>
              <tbody>
                {passingResults.map(passingResult => (
                  <ResultRow key={passingResult.name} result={passingResult} />
                ))}
              </tbody>
            </Table>
          </Accordion.Collapse>
        </Accordion>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    results: state.default.results,
  }
}

export default connect(mapStateToProps)(Results)

class UnconnectedResultRow extends Component {
  triggerRetry(event, result) {
    const formData = new FormData()
    formData.append('service', result.service)
    formData.append('activity', result.activity)
    formData.append('name', result.name)

    fetch(this.props.services.administration + '/results/retry', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).catch(function(error) {
      /* istanbul ignore next */
      console.log('There was a problem updating the transaction', result, error)
    })
  }

  deleteHistory(event, result) {
    console.log('Delete history for', result)
    console.log('This is not implemented.')
  }

  render() {
    const result = this.props.result
    return (
      <tr>
        <th scope='row'>{result.name}</th>
        <td>
          <Badge variant={result.latest_success ? 'success' : 'danger'}>
            {(result.latest_success ? 'Passed' : 'Failed') +
              ' at ' +
              moment(result.latest_created_at).format('YYYY-MM-DD HH:mm')}
          </Badge>
          {result.latest_success ? (
            ''
          ) : (
            <Alert variant='danger'>
              {result.latest_failure_reason == null
                ? '(No failure reason given.)'
                : result.latest_failure_reason}
            </Alert>
          )}
        </td>
        <td>
          <Button
            id='application.results.retryButton'
            variant='outline-secondary'
            size='sm'
            onClick={event => this.triggerRetry(event, result)}
          >
            Retry
          </Button>
          <Button
            id='application.results.deleteButton'
            variant='outline-secondary'
            size='sm'
            onClick={event => this.deleteHistory(event, result)}
          >
            Delete history
          </Button>
        </td>
      </tr>
    )
  }
}

const resultRowMapStateToProps = state => {
  return {
    services: state.default.services,
  }
}

const ResultRow = connect(resultRowMapStateToProps)(UnconnectedResultRow)
