import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'

class Entities extends Component {
  componentDidMount() {
    fetchResources('entities')
  }

  render() {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Structure</th>
          </tr>
        </thead>
        <tbody>
          {this.props.entities.map(entity => (
            <EntityRow key={entity.id} entity={entity} />
          ))}
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = state => {
  return {
    entities: state.default.entities,
  }
}

export default connect(mapStateToProps)(Entities)

class EntityRow extends Component {
  render() {
    const entity = this.props.entity

    return (
      <tr>
        <td>{entity.name}</td>
        <td>{entity.structure}</td>
      </tr>
    )
  }
}
