import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'

import Authentication from '../containers/authentication'
import fetchResources from '../utils/fetch-resources'

class Root extends Component {
  componentDidMount() {
    fetchResources('initialState')
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <BrowserRouter>
          <Authentication />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default Root
