import React, { Component } from 'react'
import { Col, Container, Nav, Navbar, NavbarBrand, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, Route, Switch } from 'react-router-dom'

import Accounts from '../components/accounts'
import Cards from '../components/cards'
import Entities from '../components/entities'
import Overview from '../components/overview'
import Results from '../components/results'
import Settings from '../components/settings'
import Transactions from '../components/transactions'

class Application extends Component {
  render() {
    return (
      <Container className='shadow my-5 rounded'>
        <Navbar>
          <NavbarBrand>Buckleys</NavbarBrand>
          <Nav className='ml-auto'>
            <Nav.Item>
              <Settings />
            </Nav.Item>
          </Nav>
        </Navbar>
        <Nav variant='tabs'>
          <Nav.Item id='application.accountsLink'>
            <Nav.Link as={Link} to='/accounts'>
              Accounts
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id='application.transactionsLink'>
            <Nav.Link as={Link} to='/transactions'>
              Transactions
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id='application.cardsLink'>
            <Nav.Link as={Link} to='/cards'>
              Cards
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id='application.entitiesLink'>
            <Nav.Link as={Link} to='/entities'>
              Entities
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id='application.resultsLink'>
            <Nav.Link as={Link} to='/results'>
              Results
            </Nav.Link>
          </Nav.Item>
          <Nav.Item id='application.overviewLink'>
            <Nav.Link as={Link} to='/overview'>
              Overview
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Row>
          <Col sm='12'>
            <Switch>
              <Route exact path='/' component={Accounts} />
              <Route exact path='/accounts' component={Accounts} />
              <Route exact path='/transactions' component={Transactions} />
              <Route exact path='/cards' component={Cards} />
              <Route exact path='/entities' component={Entities} />
              <Route exact path='/results' component={Results} />
              <Route exact path='/overview' component={Overview} />
            </Switch>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    activeTab: state.default.activeTab,
    services: state.default.services,
    session: state.default.session,
  }
}

export default withRouter(connect(mapStateToProps)(Application))
