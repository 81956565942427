import React, { Component } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Media,
  Row,
} from 'react-bootstrap'
import { connect } from 'react-redux'

import createResource from '../utils/create-resource'
import handleInputChange from '../utils/handle-input-change'

class Notes extends Component {
  state = {}

  createNote(submitEvent) {
    submitEvent.preventDefault()

    createResource(
      this.props.services.finance + '/notes',
      [
        { key: 'model_id', value: this.props.modelID },
        { key: 'model_type', value: this.props.modelType },
        { key: 'text', value: this.state.text },
      ],
      this.props.reloadResources
    )
  }

  render() {
    const notes = this.props.notes

    return (
      <>
        <Form onSubmit={submitEvent => this.createNote(submitEvent)}>
          <Form.Group as={Row} className='align-middle'>
            <Col>
              <InputGroup>
                <Form.Control
                  id={this.props.idPrefix + '.textInput'}
                  name='text'
                  onChange={handleInputChange.bind(this)}
                  placeholder='Note'
                  type='text'
                />
                <InputGroup.Append>
                  <Button
                    id={this.props.idPrefix + '.submitButton'}
                    type='submit'
                    variant='outline-secondary'
                  >
                    Add note
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Form.Group>
        </Form>
        {notes.map(note => {
          return (
            <Card className='card-note' key={note.id}>
              <Card.Header>
                <Media>
                  <Media.Body>{note.date}</Media.Body>
                  <Media.Body>{note.user_id}</Media.Body>
                </Media>
              </Card.Header>
              <Card.Body>{note.text}</Card.Body>
            </Card>
          )
        })}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    services: state.default.services,
  }
}

export default connect(mapStateToProps)(Notes)
