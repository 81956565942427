import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class Accounts extends Component {
  componentDidMount() {
    fetchResources('accounts')
  }

  render() {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {this.props.accounts.map(account => (
            <AccountRow key={account.id} account={account} />
          ))}
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = state => {
  return {
    accounts: state.default.accounts,
  }
}

export default connect(mapStateToProps)(Accounts)

class AccountRow extends Component {
  render() {
    const account = this.props.account

    return (
      <tr>
        <td>{account.name}</td>
        <td>
          {account.balance == null
            ? 'No balance'
            : formatCurrency(account.currency, account.balance)}
        </td>
      </tr>
    )
  }
}
