export function addTransactions(response) {
  return { type: 'ADD_TRANSACTIONS', value: response }
}

export function setAccounts(accounts) {
  return { type: 'SET_ACCOUNTS', value: accounts }
}

export function setCards(cards) {
  return { type: 'SET_CARDS', value: cards }
}

export function setEntities(cards) {
  return { type: 'SET_ENTITIES', value: cards }
}

export function setInitialState(state) {
  return { type: 'SET_INITIAL_STATE', value: state }
}

export function setGroup(group) {
  return { type: 'SET_GROUP', value: group }
}

export function setOverview(overview) {
  return { type: 'SET_OVERVIEW', value: overview }
}

export function setResults(results) {
  return { type: 'SET_RESULTS', value: results }
}

export function setSessionStateToSignedIn() {
  return { type: 'SET_SESSION_STATE', value: { isSignedIn: true } }
}

export function setSessionStateToSignedOut() {
  return { type: 'SET_SESSION_STATE', value: { isSignedIn: false } }
}

export function setTransactions(response) {
  return { type: 'SET_TRANSACTIONS', value: response }
}
