import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import CardCreationModal from '../components/card-creation-modal'
import fetchResources from '../utils/fetch-resources'

class Cards extends Component {
  componentDidMount() {
    fetchResources('cards')
  }

  render() {
    return (
      <div>
        <CardCreationModal />
        <Table responsive>
          <thead>
            <tr>
              <th>Description</th>
              <th>Status</th>
              <th>Issuance</th>
              <th>Use Case</th>
              <th>Vendor</th>
            </tr>
          </thead>
          <tbody>
            {this.props.cards.map(card => (
              <CardRow key={card.id} card={card} />
            ))}
          </tbody>
        </Table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    cards: state.default.cards,
  }
}

export default connect(mapStateToProps)(Cards)

class CardRow extends Component {
  render() {
    const card = this.props.card

    return (
      <tr>
        <td>{card.description}</td>
        <td>{card.status}</td>
        <td>{card.issuance}</td>
        <td>{card.use_case}</td>
        <td>{card.vendor}</td>
      </tr>
    )
  }
}
