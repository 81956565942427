/* istanbul ignore file */
export default function() {
  const commonState = {
    accounts: [],
    categoricals: {
      card: {
        issuances: [],
        statuses: [],
        useCases: [],
      },
      transaction: {
        classifications: [],
      },
    },
    cards: [],
    config: {
      plaid: { publicKey: '58cf9e1b4e95b819440e45ee' },
    },
    entities: [],
    group: { description: '', id: '', users: [] },
    mappings: {
      currencyMinorUnitCountLookup: { JPY: 0, USD: 2 },
    },
    overview: {
      databases: [],
      users: [],
    },
    results: [],
    services: { administration: '', finance: '' },
    session: { isSignedIn: false },
    transactions: [],
    transactionsMetadata: {},
    user: { email: '', id: '', username: '' },
  }

  switch (process.env.NODE_ENV) {
    case 'development':
      return {
        ...commonState,
        services: {
          administration: 'http://administration.buckleys.localhost:30000',
          finance: 'http://finance.buckleys.localhost:30001',
        },
      }
    case 'test':
      return {
        ...commonState,
        services: { administration: '', finance: '' },
      }
    case 'production':
      return {
        ...commonState,
        services: {
          administration: 'https://administration.internal.darraghbuckley.com',
          finance: 'https://finance.internal.darraghbuckley.com',
        },
      }
    default:
      throw new Error('Environment not known: ' + process.env.NODE_ENV)
  }
}
