import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import fetchResources from '../utils/fetch-resources'

class Overview extends Component {
  componentDidMount() {
    fetchResources('overview')
  }

  render() {
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Model</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {this.props.databases.map(model => (
            <ModelRow key={model.name} model={model} />
          ))}
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = state => {
  return {
    databases: state.default.overview.databases,
  }
}

export default connect(mapStateToProps)(Overview)

class ModelRow extends Component {
  render() {
    const model = this.props.model

    return (
      <tr>
        <td>{model.name}</td>
        <td>{model.count}</td>
      </tr>
    )
  }
}
