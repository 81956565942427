import React, { Component } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import PlaidLink from 'react-plaid-link'
import { connect } from 'react-redux'
import queryString from 'query-string'

import fetchResources from '../utils/fetch-resources'

class Settings extends Component {
  state = {
    showModal: false,
  }

  componentDidMount() {
    fetchResources('group')
  }

  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  /* istanbul ignore next */
  handleOnSuccess(token, metadata) {
    console.log('Token', token)
    console.log('Metadata', metadata)
  }

  render() {
    return (
      <div>
        <Button
          variant='outline-secondary'
          id='application.settingsModal.showButton'
          onClick={this.toggle.bind(this)}
        >
          Settings
        </Button>
        <Modal show={this.state.showModal} onHide={this.toggle.bind(this)}>
          <Modal.Header closeButton>Settings</Modal.Header>
          <Modal.Body>
            <PlaidLink
              clientName='Buckleys'
              env='development'
              product={['transactions']}
              publicKey={this.props.config.plaid.publicKey}
              onSuccess={this.handleOnSuccess}
              token={queryString.parse(window.location.search).plaidToken}
            >
              Connect account with Plaid
            </PlaidLink>
            Group members
            <Table responsive>
              <tbody>
                {this.props.group.users.map(user => (
                  <tr key={user.id}>
                    <td>{user.username}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.default.config,
    group: state.default.group,
    services: state.default.services,
    session: state.default.session,
  }
}

export default connect(mapStateToProps)(Settings)
