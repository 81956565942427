import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import * as actionCreators from '../actions/action-creator'
import handleInputChange from '../utils/handle-input-change'

class SignIn extends Component {
  state = { username: '', password: '' }

  onSubmit(submitEvent) {
    submitEvent.preventDefault()

    const formData = new FormData()
    formData.append('username', this.state.username)
    formData.append('password', this.state.password)

    fetch(this.props.services.finance + '/session', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).then(response => {
      /* istanbul ignore next */
      if (response.status === 200) {
        this.props.dispatch(actionCreators.setSessionStateToSignedIn())
      }
    })
  }

  render() {
    return (
      <Modal show>
        <Form
          method='post'
          onSubmit={submitEvent => this.onSubmit(submitEvent)}
        >
          <Modal.Header>Sign-in</Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                Username
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type='text'
                  id='signIn.usernameInput'
                  name='username'
                  placeholder='placeholder'
                  onChange={handleInputChange.bind(this)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={3}>
                Password
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type='password'
                  id='signIn.passwordInput'
                  name='password'
                  placeholder='•••••'
                  onChange={handleInputChange.bind(this)}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type='submit' id='signIn.signInButton'>
              Sign in
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

SignIn.defaultProps = {
  services: { finance: '' },
}

const mapStateToProps = (state, ownProps) => {
  return { services: state.default.services }
}

export default connect(mapStateToProps)(SignIn)
