import moment from 'moment'
import React, { Component } from 'react'
import { Form, Modal, Table } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'

import Notes from '../components/notes'
import fetchResources from '../utils/fetch-resources'
import formatCurrency from '../utils/format-currency'

class Transactions extends Component {
  state = {
    transactionDetailsModalState: {
      showModal: false,
      transaction: null,
    },
  }

  componentDidMount() {
    fetchResources('transactions')
  }

  setTransactionDetailsModalState(transactionDetailsModalState) {
    this.setState({
      transactionDetailsModalState: transactionDetailsModalState,
    })
  }

  render() {
    return (
      <div>
        <TransactionDetails
          showModal={this.state.transactionDetailsModalState.showModal}
          transaction={this.state.transactionDetailsModalState.transaction}
          setTransactionDetailsModalState={this.setTransactionDetailsModalState.bind(
            this
          )}
        />
        <InfiniteScroll
          dataLength={this.props.transactions.length}
          next={() =>
            fetchResources('transactions', this.props.transactionsMetadata)
          }
          hasMore={this.props.transactionsMetadata.next_offset !== null}
        >
          <Table responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Classification</th>
              </tr>
            </thead>
            <tbody>
              {this.props.transactions.map((transaction, index) => (
                <TransactionRow
                  key={transaction.id}
                  index={index}
                  transaction={transaction}
                  setTransactionDetailsModalState={this.setTransactionDetailsModalState.bind(
                    this
                  )}
                />
              ))}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    transactions: state.default.transactions,
    transactionsMetadata: state.default.transactionsMetadata,
  }
}

export default connect(mapStateToProps)(Transactions)

class UnconnectedTransactionRow extends Component {
  state = {
    classification: this.props.transaction.classification,
  }

  updateTransaction(event, id) {
    const target = event.target
    const value = target.value

    this.setState({ classification: value })

    const formData = new FormData()
    formData.append('classification', value)

    fetch(this.props.services.finance + '/transactions/' + id, {
      method: 'PATCH',
      credentials: 'include',
      body: formData,
    }).catch(function(error) {
      /* istanbul ignore next */
      console.log('There was a problem updating the transaction', id, error)
    })
  }

  showTransactionDetails(transaction) {
    this.props.setTransactionDetailsModalState({
      showModal: true,
      transaction: transaction,
    })
  }

  render() {
    const transaction = this.props.transaction

    return (
      <tr>
        <td>{moment(transaction.date).format('YYYY-MM-DD')}</td>
        <td
          onClick={() => {
            this.showTransactionDetails(transaction)
          }}
          id={'application.transactions.' + this.props.index + '.description'}
        >
          {transaction.description}
        </td>
        <td>{formatCurrency(transaction.currency, transaction.amount)}</td>
        <td>
          <Form.Control
            as='select'
            id={
              'application.transactions.' +
              this.props.index +
              '.classificationInput'
            }
            name='classification'
            onChange={event => this.updateTransaction(event, transaction.id)}
            value={this.state.classification}
            size='sm'
          >
            {this.props.classifications.map(classification => (
              <option key={classification}>{classification}</option>
            ))}
          </Form.Control>
        </td>
      </tr>
    )
  }
}

const transactionRowMapStateToProps = state => {
  return {
    classifications: state.default.categoricals.transaction.classifications,
    services: state.default.services,
    session: state.default.session,
  }
}

const TransactionRow = connect(transactionRowMapStateToProps)(
  UnconnectedTransactionRow
)

class TransactionDetails extends React.Component {
  toggle() {
    this.props.setTransactionDetailsModalState({
      showModal: !this.props.showModal,
    })
  }

  render() {
    const transaction = this.props.transaction

    return (
      <div>
        <Modal
          id='application.transactions.detailsModal'
          show={this.props.showModal}
          onHide={this.toggle.bind(this)}
        >
          {transaction ? (
            <div>
              <Modal.Header closeButton>Transaction details</Modal.Header>
              <Modal.Body>
                <pre>{JSON.stringify(transaction, null, 2)}</pre>
              </Modal.Body>
              <Notes
                idPrefix='application.transactions.detailsModal'
                modelID={transaction.id}
                modelType='Finance::BankAccountTransaction'
                notes={transaction.notes}
                reloadResources={() => {
                  fetchResources('transactions')
                }}
              />
            </div>
          ) : (
            ''
          )}
        </Modal>
      </div>
    )
  }
}
