import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'

import fetchResources from '../utils/fetch-resources'
import handleInputChange from '../utils/handle-input-change'

class CardCreationModal extends Component {
  state = {
    showModal: false,
    description: '',
    issuance: 'virtual',
    useCase: 'applepay',
    status: 'active',
    vendor: '',
  }

  toggle() {
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  onSubmit(submitEvent) {
    submitEvent.preventDefault()

    const formData = new FormData()
    if (this.state.description.length >= 1) {
      formData.append('description', this.state.description)
    }
    formData.append('issuance', this.state.issuance)
    formData.append('status', this.state.status)
    formData.append('use_case', this.state.useCase)
    if (this.state.vendor.length >= 1) {
      formData.append('vendor', this.state.vendor)
    }

    fetch(this.props.services.finance + '/cards', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    }).then(response => {
      /* istanbul ignore next */
      if (response.status === 200) {
        fetchResources('cards')
      }
    })

    this.toggle()
  }

  render() {
    return (
      <>
        <Button
          variant='outline-secondary'
          className='float-right'
          onClick={this.toggle.bind(this)}
          id='application.cards.cardCreationModal.addButton'
        >
          +
        </Button>
        <Modal show={this.state.showModal} onHide={this.toggle.bind(this)}>
          <Form
            method='post'
            onSubmit={submitEvent => this.onSubmit(submitEvent)}
          >
            <Modal.Header closeButton>Create card</Modal.Header>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Description
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type='text'
                    id='application.cards.cardCreationModal.descriptionInput'
                    name='description'
                    placeholder='Optional description'
                    onChange={handleInputChange.bind(this)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Status
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    id='application.cards.cardCreationModal.statusInput'
                    name='status'
                    onChange={handleInputChange.bind(this)}
                    value={this.state.status}
                  >
                    {this.props.statuses.map(status => (
                      <option key={status}>{status}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Issuance
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    id='application.cards.cardCreationModal.issuanceInput'
                    name='issuance'
                    onChange={handleInputChange.bind(this)}
                    value={this.state.issuance}
                  >
                    {this.props.issuances.map(issuance => (
                      <option key={issuance}>{issuance}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Use case
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    as='select'
                    id='application.cards.cardCreationModal.useCaseInput'
                    name='useCase'
                    onChange={handleInputChange.bind(this)}
                    value={this.state.useCase}
                  >
                    {this.props.useCases.map(useCase => (
                      <option key={useCase}>{useCase}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  Vendor
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type='text'
                    id='application.cards.cardCreationModal.vendorInput'
                    name='vendor'
                    placeholder='Optional vendor'
                    onChange={handleInputChange.bind(this)}
                  />
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='primary'
                id='application.cards.cardCreationModal.submitButton'
              >
                Create card
              </Button>
              <Button
                variant='outline-primary'
                onClick={this.toggle.bind(this)}
                id='application.cards.cardCreationModal.cancelButton'
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    issuances: state.default.categoricals.card.issuances,
    services: state.default.services,
    statuses: state.default.categoricals.card.statuses,
    useCases: state.default.categoricals.card.useCases,
  }
}

export default connect(mapStateToProps)(CardCreationModal)
