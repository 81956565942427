import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Application from '../containers/application'
import SignIn from '../containers/sign-in'
import fetchResources from '../utils/fetch-resources'

class Authentication extends Component {
  componentDidMount() {
    fetchResources('session')
  }

  render() {
    if (this.props.session.isSignedIn) {
      return <Application />
    } else {
      return <SignIn />
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    session: state.default.session,
  }
}

export default withRouter(connect(mapStateToProps)(Authentication))
